import React from "react";
import PortfolioPage from "../../index";
import { printMediaContent } from "../../data";

export default function PrintMediaPage() {
  return (
    <div>
      <PortfolioPage heading="Print Media" data={printMediaContent} />
    </div>
  );
}
